<template>
    <div class="wrap">
        <el-page-header @back="$router.back()" :content="title" class="margin-bottom margin-top-xs"></el-page-header>
        <el-alert title="操作提示" type="success" class="margin-bottom">
            <div>
                1. 标识“*”的选项为必填项，其余为选填项。<br />
                2. 请按提示栏信息进行商品添加。
            </div>
        </el-alert>
        <div class="box">
            <div class="form_box">
                <el-form ref="form" :model="form" label-width="120px" v-loading="loading">
                    <el-form-item label="员工姓名" prop="name" :rules="rules.required">
                        <el-input v-model="form.name" maxlength="30"></el-input>
                    </el-form-item>
                    <el-form-item label="工资日期" :rules="rules.required">
                        <el-date-picker
                                v-model="changeTime"
                                type="month"
                                format="yyyy 年 MM 月"
                                value-format="yyyy-MM"
                                placeholder="选择年月"
                                @change="getTime"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="合计支出工资" :rules="rules.required">
                        <el-input-number v-model="form.total_wages" :precision="2" :min="0" :step="1" :max="9999999"></el-input-number>
                    </el-form-item>
                    <el-form-item label="基本工资" :rules="rules.required">
                        <el-input-number v-model="form.base_pay" :precision="2" :min="0" :step="1" :max="9999999"></el-input-number>
                    </el-form-item>
                    <el-form-item label="考核-综合奖" :rules="rules.required">
                        <el-input-number v-model="form.comprehensive" :precision="2" :min="0" :step="1" :max="9999999"></el-input-number>
                    </el-form-item>
                    <el-form-item label="考核-扣考勤" :rules="rules.required">
                        <el-input-number v-model="form.check_work" :precision="2" :min="0" :step="1" :max="9999999"></el-input-number>
                    </el-form-item>
                    <el-form-item label="考核-扣违章" :rules="rules.required">
                        <el-input-number v-model="form.violation" :precision="2" :min="0" :step="1" :max="9999999"></el-input-number>
                    </el-form-item>
                    <el-form-item label="考核-扣其他" :rules="rules.required">
                        <el-input-number v-model="form.other" :precision="2" :min="0" :step="1" :max="9999999"></el-input-number>
                    </el-form-item>
                    <el-form-item label="考核-小计-加/减" :rules="rules.required">
                        <el-input-number v-model="form.subtotal" :precision="2" :min="0" :step="1" :max="9999999"></el-input-number>
                    </el-form-item>
                    <el-form-item label="提成-疗程部分" :rules="rules.required">
                        <el-input-number v-model="form.course_treatment" :precision="2" :min="0" :step="1" :max="9999999"></el-input-number>
                    </el-form-item>
                    <el-form-item label="提成-产品部分" :rules="rules.required">
                        <el-input-number v-model="form.product" :precision="2" :min="0" :step="1" :max="9999999"></el-input-number>
                    </el-form-item>
                    <el-form-item label="合计" :rules="rules.required">
                        <el-input-number v-model="form.total" :precision="2" :min="0" :step="1" :max="9999999"></el-input-number>
                    </el-form-item>
                    <el-form-item label="员工是否签字" :rules="rules.required">
                        <el-switch v-model="form.auto" :active-value="1" :inactive-value="0"></el-switch>
                        <span v-if="form.auto == 0">未签字</span>
                        <span v-if="form.auto == 1">已签字</span>
                    </el-form-item>
                    <el-form-item label="审批人"  :rules="rules.required">
                        <el-input v-model="form.approved" maxlength="30"></el-input>
                    </el-form-item>
                    <el-form-item label="审批日期" :rules="rules.required">
                        <el-date-picker
                                v-model="form.approved_date"
                                type="date"
                                format="yyyy 年 MM 月 dd 日"
                                value-format="yyyy-MM-dd"
                                placeholder="选择日期"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="备注" :rules="rules.required">
                        <el-input type="textarea" v-model="form.remark"></el-input>
                    </el-form-item>
                    <el-form-item label="核算日期" :rules="rules.required">
                        <el-date-picker
                                v-model="form.business_date"
                                type="date"
                                format="yyyy 年 MM 月 dd 日"
                                value-format="yyyy-MM-dd"
                                placeholder="选择日期"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="核算制表人" :rules="rules.required">
                        <el-input v-model="form.business_accounting"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="onSubmit()">保存</el-button>
                        <!-- <el-button type="primary" @click="onSubmit()">保存并继续添加</el-button> -->
                        <el-button @click="$router.back()">取消</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>
<script>
    import rules from '../../../common/common.rules.js'
    export default {
        data() {
            return {
                rules,
                classify: [],
                loading: false,
                changeTime:'',
                form: {
                    id: this.$route.params.id, // int 服务ID
                    name: '', // string 员工姓名
                    year: '', // string 年份
                    month: '', // string 月份
                    business_accounting: '', // string 核算制表人
                    business_date: '', // string 核算日期
                    approved: '', // string 审批人
                    approved_date: '', // string 审批日期
                    total_wages: 0, // string 合计支出工资
                    base_pay: 0, // string 基本工资
                    comprehensive: 0, // string 考核-综合奖
                    check_work: 0, // string 考核-扣考勤
                    violation: 0, // string 考核-扣违章
                    other: 0, // string  	考核-扣其他
                    subtotal: 0, // string 考核-小计-加/减
                    course_treatment: 0, // string  	销售提成-疗程部分
                    product: 0, // string 销售提成-产品部分
                    total: 0, // string 合计
                    auto:0,//员工签名
                    remark:'',//备注

                }
            };
        },
        computed: {
            title() {
                if (this.form.id) {
                    return '编辑工资核算'
                } else {
                    return '新增工资核算'
                }
            }
        },
        mounted() {
            if (this.form.id) {
                this.$api.table.wagesTotalDetails({
                    id: this.form.id
                }).then(res => {
                    console.log(res)
                    this.form = res.data
                    this.changeTime = res.data.year + '-' + res.data.month
                    this.form.auto = parseInt(res.data.auto)
                })
            }
        },
        methods: {
            getTime(){
                console.log(this.changeTime)
                this.form.year = this.changeTime.substring(0,4)
                this.form.month = this.changeTime.substring(this.changeTime.length - 2,this.changeTime.length)
                console.log(this.form.year)
                console.log(this.form.month)
            },
            onSubmit() {
                this.$refs.form.validate(valid => {
                    if (valid) {
                        const loading = this.$loading();
                        this.$api.table.saveWagesTotal(this.form).then(res => {
                            loading.close();
                            if (res.code == 200) {
                                // 保存成功
                                this.$message.success(`保存成功`);
                                this.$router.back();
                            } else {
                                this.$message.error(res.msg);
                            }
                        }).catch(err => {
                            loading.close();
                            this.$alert(err);
                        });
                    } else {
                        this.$message.warning(`请检查输入错误`);
                        return false;
                    }
                });
            }
        },
    };
</script>

<style scoped lang="scss">
    .form_box {

        .el-input,
        .el-textarea {
            width: 500px;
        }
    }

</style>
